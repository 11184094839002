import * as React from "react";
import FullscreenMenu from "./menu/FullscreenMenu";
import HamburgerButton from "./menu/HamburgerButton";
import Logo from "./menu/Logo";

/**
 * Header props
 */
type HeaderProps = {
  siteTitle: string
};

/**
 * Header props
 */
type HeaderState = {
  isMenuOpen: boolean
};

/**
 * A header
 */
export class Header extends React.Component<HeaderProps, HeaderState> {
  constructor (props: HeaderProps) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  /**
   * Render
   */
  render () {
    return (
      <header>
        <nav>
          <div className="menu">
            <HamburgerButton
              isMenuOpen={this.state.isMenuOpen}
              onClick={() => {
                this.setState({ isMenuOpen: !this.state.isMenuOpen });
              }}
            />

            <div className="logo">
              <Logo isMenuImage={false} isOpen={this.state.isMenuOpen}></Logo>
            </div>
          </div>

          <FullscreenMenu setIsOpen={(isOpen) => this.setState({isMenuOpen: isOpen})} isOpened={this.state.isMenuOpen} />
        </nav>
      </header>
    );
  }
}
