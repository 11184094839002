import { ArrowLeftOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Image, Menu, PageHeader, Row, Tag } from "antd";
import React from "react";
import { Hero } from "react-hero-transition";
import { ProjectProps } from "./Project";

const FullProject = (props: Pick<ProjectProps, "id" | "content" | "imageUrl" | "title" | "tags">) => {
    const heroImageId = props.id + "Image";
    const heroTitleId = props.id + "Title";

    const menu = (
        <Menu>
            <Menu.Item>
                <a>
                    Test
                </a>
            </Menu.Item>
        </Menu>
    );

    const DropdownMenu = () => (
        <Dropdown key="more" overlay={menu}>
            <Button
                style={{
                    border: "none",
                    padding: 0,
                }}
            >
                <EllipsisOutlined
                    style={{
                        fontSize: 20,
                        verticalAlign: "top",
                    }}
                />
            </Button>
        </Dropdown>
    );

    return <>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <PageHeader
                    className="site-page-header"
                    backIcon={<ArrowLeftOutlined />}
                    title={props.title}
                    tags={props.tags.map(tag => <Tag>{tag}</Tag>)}
                    extra={[<DropdownMenu key="more" />]}
                />
            </Col>

            <Image.PreviewGroup>
                <Col span={12}>
                    <Image
                        src={"/assets/project-3.png"}
                    />
                </Col>

                <Col span={12}>
                    <Image
                        src={props.imageUrl}
                    />
                </Col>
            </Image.PreviewGroup>
        </Row>
    </>;

    return <div className="full project">
        <div className="images">
            <div className="secondary image">
                <img />
            </div>

            <Hero id={heroImageId}>
                <div className="primary image">
                    <img />
                </div>
            </Hero>
        </div>

        <Hero id={heroTitleId}>
            <div className="header">
                <h4>
                    {props.title}
                </h4>
            </div>
        </Hero>
    </div>;
};

export default FullProject;