import  { Experience, RawExperience } from "../Experience";
import IConverter from "./IConverter";
import ImageConverter from "./ImageConverter";

/**
 * An experience converter
 */
class ExperienceConverter implements IConverter<RawExperience, Experience> {

    /**
     * Convert the input
     * @param input  the input
     * @returns the output
     */
    convert (input: RawExperience): Experience {
        const companyLogo = new ImageConverter().convert(input.companyLogo);

        return {
            company: {
                logo: companyLogo,
                location: input.companyLocation,
                title: input.companyTitle,
            },
            description: input.description,
            startDate: input.startDate,
            endDate: input.endDate,
            title: input.title,
            type: input.type,
            createdAt: input.created_at,
            updatedAt: input.updated_at,
        };
    }

}

export default ExperienceConverter;