import React, { useEffect, useState } from "react";
import { useSpring } from "react-spring";
import useMousePosition from "../../util/useMousePosition";
import FullscreenMenuItem from "./FullscreenMenuItem";
import Logo from "./Logo";

/**
 * Props of a fullscreen menu
 */
type FullscreenMenuProps = {
    isOpened: boolean
    setIsOpen: (isOpen: boolean) => void;
};

const FullscreenMenu = (props: FullscreenMenuProps) => {
    const { x, y } = useMousePosition();

    const [size, setSize] = useState<{ x: number, y: number }>({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    const onClick = () => {
        props.setIsOpen(false);
    };

    const angle = (cx: number, cy: number, ex: number, ey: number) => {
        const dy = ey - cy;
        const dx = ex - cx;
        let theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        // if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    };

    const pointX = size.x * 1.7;
    const pointY = 0;

    return (
        <div
            className="fullscreen-navigation"
            style={{ visibility: props.isOpened ? "inherit" : "hidden", background: `linear-gradient(${-135 + angle(x, y, pointX, pointY)}deg, #9e0b63 0, #0892b2 100%)` }}
        >


            <div className="items">
                <ul className="menu-items-animated">
                    <li>
                        <div className="image">
                            <Logo isMenuImage={true} isOpen={props.isOpened}></Logo>
                        </div>
                    </li>
                    <FullscreenMenuItem onClick={onClick} opened={props.isOpened} title="Home" url="" id="home" />
                    <FullscreenMenuItem onClick={onClick} opened={props.isOpened} title="Berufserfahrung" url="/experience" id="experience" />
                    <FullscreenMenuItem onClick={onClick} opened={props.isOpened} title="Ausbildung" url="/studies" id="studies" />
                    <FullscreenMenuItem onClick={onClick} opened={props.isOpened} title="Persönliches" url="/personal" id="personal" />
                </ul>
            </div>

            <div className="footer">
                <div className="copyright">
                    <span>
                        &copy; <span className="year">2018 - 2021</span> Finn Tegeler
                    </span>
                </div>

                <div className="items">
                    <ul className="menu-items-animated">
                        <li>
                            <a>Impressum</a>
                        </li>

                        <li>
                            <a>Datenschutz</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FullscreenMenu;
