import { RawStudies, Studies } from "../Studies";
import IConverter from "./IConverter";
import ImageConverter from "./ImageConverter";

/**
 * An experience converter
 */
class StudiesConverter implements IConverter<RawStudies, Studies> {

    /**
     * Convert the input
     * @param input  the input
     * @returns the output
     */
    convert (input: RawStudies): Studies {
        const companyLogo = new ImageConverter().convert(input.companyLogo);

        return {
            company: {
                logo: companyLogo,
                location: input.companyLocation,
                title: input.companyTitle,
            },
            description: input.description,
            startDate: input.startDate,
            endDate: input.endDate,
            title: input.title,
            degree: input.degree,
            createdAt: input.created_at,
            updatedAt: input.updated_at,
        };
    }

}

export default StudiesConverter;