import moment from "moment";
import "moment/locale/de";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "./Router";
import "./styles/antd.less";
import "./styles/main.scss";


moment.locale("de");

ReactDOM.render(<Router />, document.getElementById("root"));
