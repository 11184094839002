import { LoadingOutlined } from "@ant-design/icons";
import { List, Row, Spin } from "antd";
import React from "react";
import { Experience } from "../../data/Experience";

import ExperienceItem from "./ExperienceItem";

/**
 * props of a project list
 */
export type ExperiencesProps = {
    experiences: Experience[];
    areExperiencesLoaded: null | boolean;
};

const ExperienceList = (props: ExperiencesProps) => {

    return <>
        {props.areExperiencesLoaded && <Row gutter={[8, 8]}>{props.experiences.map(experience =>
            <ExperienceItem key={experience.title} experience={experience} />
        )}</Row>}

        {!props.areExperiencesLoaded && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin={true} />}></Spin>}
    </>;
};

export default ExperienceList;