import { LoadingOutlined } from "@ant-design/icons";
import { Row, Spin } from "antd";
import React from "react";
import { Studies } from "../../data/Studies";

import StudiesItem from "./StudiesItem";

/**
 * props of a project list
 */
export type StudiesListProps = {
    studies: Studies[];
    areStudiesLoaded: null | boolean;
};

const StudiesList = (props: StudiesListProps) => {

    return <>
        {props.areStudiesLoaded && <Row gutter={[8, 8]}>{props.studies.map(studies =>
            <StudiesItem key={studies.title} studies={studies} />
        )}</Row>}

        {!props.areStudiesLoaded && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin={true} />}></Spin>}
    </>;
};

export default StudiesList;