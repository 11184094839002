import { Avatar, Col, List, Row, Space, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Experience } from "../../data/Experience";


/**
 * props of a project list
 */
export type ExperienceProps = {
    experience: Experience;
};


const ExperienceItem = (props: ExperienceProps) => {
    const date = moment(props.experience.startDate).format("MMM[ ]YYYY") + "-"
        + (props.experience.endDate === null ? "Heute" : moment(props.experience.endDate).format("MMM[ ]YYYY"));

    const amount = moment(props.experience.startDate).to(props.experience.endDate ?? new Date(), true);

    const breakpoint = useBreakpoint();

    return <Col sm={12} xs={24}>
        <Row gutter={[8, 8]}>
            <Col md={2} sm={24} style={{width: "100%"}}>
                <Space align="center" style={{width: "100%", justifyContent: "center"}}><Avatar size={64} shape="square"
                    src={props.experience.company.logo.url} />
                </Space>
            </Col>

            <Col md={22} sm={24}>
                <Row>
                    <Col span={24}>
                        <Typography.Paragraph style={{ marginBottom: ".1rem", fontSize: "110%" }} strong={true}>
                            {props.experience.title}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ marginBottom: ".1rem" }} type="secondary">
                            {props.experience.company.title + " (" + props.experience.type + ")"}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ marginBottom: ".1rem" }} type="secondary">
                            {date + " (" + amount + ")"}
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">
                            {props.experience.company.location}
                        </Typography.Paragraph>
                    </Col>
                    <Col>
                        <div className="react-markdown-wrapper">
                            <ReactMarkdown>{props.experience.description}</ReactMarkdown>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>;
};

export default ExperienceItem;