import React from "react";
import { Hero } from "react-hero-transition";

/**
 * Props of the logo
 */
type LogoProps = {
    isOpen: boolean;
    isMenuImage: boolean;
};

const Logo = (props: LogoProps) => {
    return <>
        {props.isOpen && props.isMenuImage && <Hero id="logo">
            <img src="/assets/logo_white.png" /></Hero>}
        {!props.isOpen && !props.isMenuImage && <Hero id="logo"><img src="/assets/logo.png" /></Hero>}
    </>;
};

export default Logo;