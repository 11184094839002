import React from "react";
import { Hero } from "react-hero-transition";
import { MenuHeader } from "../generic/MenuHeader";

/**
 * Props of a hamburger butten
 */
type FullscreenMenuItemProps = {
  opened: boolean;
  title: string;
  url: string;
  id: string;
  onClick: () => void;
};

const FullscreenMenuItem = (props: FullscreenMenuItemProps) => {
  return (
    <li>
      <Hero id={props.id}><MenuHeader onClick={props.onClick} inMenu={props.opened} title={props.title} url={props.url} /></Hero>
    </li>
  );
};

export default FullscreenMenuItem;
