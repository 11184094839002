import { ArrowLeftOutlined, FileTextOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Image, Layout, List, PageHeader, Result, Row, Typography } from "antd";
import React from "react";
import { Hero, TransitionProvider } from "react-hero-transition";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import ExperienceItem from "./components/experience/ExperienceItem";
import ExperienceList from "./components/experience/ExperienceList";
import { MenuHeader } from "./components/generic/MenuHeader";
import { Header as TopHeader } from "./components/header";
import FullProject from "./components/projects/FullProject";
import Projects from "./components/projects/Projects";
import { SEO } from "./components/seo";
import ExperienceConverter from "./data/converter/ExperienceConverter";
import { Experience, RawExperience } from "./data/Experience";

import moment from "moment";
import StudiesList from "./components/studies/StudiesList";
import StudiesConverter from "./data/converter/StudiesConverter";
import { RawStudies, Studies } from "./data/Studies";

/**
 * The props
 */
export type RouterProps = {};

/**
 * The state
 */
export type RouterState = {
    experiences: Experience[];
    areExperiencesLoaded: null | boolean;
    studies: Studies[];
    areStudiesLoaded: null | boolean;
};

const apiUrl = "https://homepage-api.cloud.finn-tegeler.de/";

/**
 * Router site
 */
export class Router extends React.Component<RouterProps, RouterState> {

    constructor (props: RouterProps) {
        super(props);

        this.state = {
            experiences: [],
            areExperiencesLoaded: null,
            studies: [],
            areStudiesLoaded: null,
        };

        this.loadExperiences = this.loadExperiences.bind(this);
        this.loadStudies = this.loadStudies.bind(this);
    }

    /**
     * Triggered on load
     */
    componentDidMount () {
        this.loadExperiences();
        this.loadStudies();
    }

    /**
     * Loads the experiences
     */
    loadExperiences () {
        fetch(apiUrl + "experiences")
            .then(res => res.json())
            .then((data: RawExperience[]) => {
                const converter = new ExperienceConverter();

                const experiences = data.map<Experience>(rawExperience => converter.convert(rawExperience)).sort((a, b) => {
                    if (moment(a.startDate).isSame(b.startDate)) { return 0; }

                    return moment(a.startDate).isBefore(b.startDate) ? 1 : -1;
                });

                this.setState({
                    areExperiencesLoaded: true,
                    experiences,
                });
            });
    }

    /**
     * Loads the experiences
     */
    loadStudies () {
        fetch(apiUrl + "studies")
            .then(res => res.json())
            .then((data: RawStudies[]) => {
                const converter = new StudiesConverter();

                const studies = data.map<Studies>(rawStudies => converter.convert(rawStudies)).sort((a, b) => {
                    if (moment(a.startDate).isSame(b.startDate)) { return 0; }

                    return moment(a.startDate).isBefore(b.startDate) ? 1 : -1;
                });

                this.setState({
                    areStudiesLoaded: true,
                    studies,
                });
            });
    }

    /**
     * Render site
     */
    render () {
        return (
            <BrowserRouter>
                <TransitionProvider>
                    <Layout className="layout" style={{ minHeight: "100vh" }}>
                        <SEO title="Finn Tegeler" />
                        <TopHeader siteTitle={`Title`} />
                        <Breadcrumb style={{ margin: "21px 0", padding: "0 64px" }}>
                            <Route exact path="/">
                                <Breadcrumb.Item>&ensp;</Breadcrumb.Item>
                            </Route>

                            <Route exact path="/experience">
                                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Berufserfahrung</Breadcrumb.Item>
                            </Route>

                            <Route exact path="/studies">
                                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Ausbildung</Breadcrumb.Item>
                            </Route>
                        </Breadcrumb>
                        <Layout.Content className="site-layout" style={{ padding: "0 64px" }}>
                            <Switch>
                                <Route exact path="/">
                                    <Row style={{ width: "100%" }}>
                                        <Col span={6} style={{ minHeight: 380, padding: "0 32px 0 0" }}>
                                            <div style={{
                                                background: "#fff",
                                                width: "100%",
                                                height: "100%",
                                            }}>
                                                <Row>
                                                    <Col span={24} style={{ minHeight: "200px" }}>
                                                        <Button block={true} type="text" style={{ height: "100%" }}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <FileTextOutlined style={{ fontSize: "250%" }} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Typography.Text>
                                                                        Berufserfahrung
                                                                    </Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col span={12} style={{ padding: "0 32px", minHeight: 380 }}>
                                            <div style={{
                                                background: "#fff",
                                                width: "100%",
                                                height: "100%",
                                            }}>
                                                <Result title="Finn Tegeler" subTitle="" icon={<Image width={300} preview={false} src="https://homepage-api.cloud.finn-tegeler.de/uploads/logo_rect_5e42a85c73.png"></Image>} />

                                                <Typography.Paragraph style={{padding: "24px"}}>
                                                Nachdem ich mir sieben Jahre lang autodidaktisch die Entwicklung von Plugins in Java,
                                                sowie Web-Anwendungen mit verschiedenen Stacks (PHP, React, Socket.IO, Laravel) beigebracht habe,
                                                studiere ich nun Smart Building Engineering an der FH Aachen.
                                                Neben meinem Interesse für integrierte Systeme, Datenvernetzung und Netzwerktechnik bewegte mich auch
                                                die nachhaltige Gestaltung und Entwicklung von Gebäuden zur Wahl des Studienganges.
                                                Ich arbeite zusätzlich als Werkstudent im Bereich Forschung und Entwicklung bei der Formitas AG
                                                und bei der FH Aachen. Außerdem bin ich selbstständig.
                                                </Typography.Paragraph>
                                            </div>
                                        </Col>
                                        <Col span={6} style={{ padding: "0 0 0 32px", minHeight: 380 }}>
                                            <div style={{
                                                background: "#fff",
                                                width: "100%",
                                                height: "100%",
                                            }}>
                                            </div>
                                        </Col>
                                    </Row>
                                </Route>

                                <Route exact path="/*">
                                    <div className="site-layout-background"
                                        style={{ padding: 24, minHeight: 380, background: "#fff", width: "100%" }}
                                    >
                                        <Route exact path="/experience">
                                            <Hero id="experience">
                                                <MenuHeader inMenu={false} title="Berufserfahrung" url="/experience"></MenuHeader>
                                            </Hero>

                                            <ExperienceList {...this.state} />
                                        </Route>

                                        <Route exact path="/studies">
                                            <Hero id="studies">
                                                <MenuHeader inMenu={false} title="Ausbildung" url="/studies"></MenuHeader>
                                            </Hero>

                                            <StudiesList {...this.state} />
                                        </Route>

                                        <Route exact path="/projects" render={props => <Projects history={props.history} />} />

                                        <Route exact path="/projects/test1">
                                            <FullProject tags={["Website", "Laravel", "Docker"]} id="test1" title="Test Project" imageUrl="/assets/project-1.png" content="Test content with a lot of letters that do not have any meaning attached to them" />
                                        </Route>
                                    </div>
                                </Route>
                            </Switch>
                        </Layout.Content>


                        <Layout.Footer style={{ textAlign: "center" }}>Ant Design ©2018 Created by Ant UED</Layout.Footer>
                    </Layout>
                </TransitionProvider>
            </BrowserRouter>
        );
    }
}