import React from "react";
import { Link } from "react-router-dom";

/**
 * Props of a menu header
 */
type MenuHeaderProps = {
    inMenu: boolean;
    title: string;
    url: string;
    onClick?: () => void;
};

/**
 * A menu header
 */
export class MenuHeader extends React.Component<MenuHeaderProps, {}> {

    /**
     * Render
     */
    render () {
        return <>
        {this.props.inMenu && <Link onClick={this.props.onClick} to={this.props.url}>{this.props.title}</Link>}
        {!this.props.inMenu && <p>{this.props.title}</p>}
    </>;
    }
}