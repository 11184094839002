import { interpolate } from "react-spring";
import { Image, ImageFormat, RawImage } from "../Image";
import IConverter from "./IConverter";

/**
 * An image converter
 */
class ImageConverter implements IConverter<RawImage, Image> {

    /**
     * Convert the input
     * @param input the input
     */
    convert (input: RawImage): Image {
        const imageBaseUrl = "https://homepage-api.cloud.finn-tegeler.de";

        return {
            alternativeText: input.alternativeText,
            caption: input.caption,
            createdAt: input.created_at,
            formats: Object.entries(input.formats).map<ImageFormat>((entry) => {
                return {
                    type: entry[0] === "thumbnail" ? "thumbnail" : entry[0] === "small" ? "small" : entry[0] === "medium" ? "medium" : entry[0] === "large" ? "large" : "original",
                    height: entry[1].height,
                    mime: entry[1].mime,
                    width: entry[1].width,
                    size: entry[1].size,
                    name: entry[1].name,
                    url: imageBaseUrl + entry[1].url,
                };
            }),
            height: input.height,
            id: input.id,
            mime: input.mime,
            name: input.name,
            size: input.size,
            type: "original",
            updatedAt: input.updated_at,
            url: imageBaseUrl + input.url,
            width: input.width,
        };
    }

}

export default ImageConverter;