import { EyeOutlined } from "@ant-design/icons";
import { Card, Col, Image, Row, Tag } from "antd";
import { History } from "history";
import React from "react";
import { Hero } from "react-hero-transition";
import { Link } from "react-router-dom";

/**
 * Props of a project
 */
export type ProjectProps = {
    imageUrl: string,
    title: string,
    tags: string[],
    content: string,
    id: string,
    history: History<unknown>;
};


const Project = (props: ProjectProps) => {
    const heroImageId = props.id + "Image";
    const heroTitleId = props.id + "Title";

    return <Card cover={<Image src={props.imageUrl} alt={props.title} />} actions={[
        <EyeOutlined onClick={() => {
            props.history.push("/projects/" + props.id);
        }} />
    ]}>
        <Card.Meta title={props.title} description={<Row gutter={[8, 8]}>
            <Col span={24}>
                <Tag>Website</Tag>
                <Tag>Laravel</Tag>
                <Tag>Docker</Tag>
            </Col>

            <Col span={24}>
                {props.content}
            </Col>
        </Row>} />
    </Card>;

    return <div className="project">
        <div className="wrapper">
            <div className="image">
                <div className="wrapper">
                    <Hero id={heroImageId}>
                        <img src={props.imageUrl} alt={props.title} />
                    </Hero>
                </div>
            </div>

            <div className="content">
                <Hero id={heroTitleId}>
                    <h4 className="header">
                        {props.title}
                    </h4>
                </Hero>

                <p>
                    {props.content}
                </p>

                <div className="button ripple">
                    <Link to={"/projects/" + props.id}>
                        Test
                    </Link>
                </div>
            </div>
        </div>
    </div>;
};

export default Project;