import React from "react";

/**
 * Props of a hamburger butten
 */
type HamburgerButtonProps = {
  isMenuOpen: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
};

const HamburgerButton = (props: HamburgerButtonProps) => {
  return (
    <div className={"hamburger" + (props.isMenuOpen ? " checked" : "")} onClick={props.onClick}>
      <div className="hamburger flow-text">
        <div></div>
      </div>
    </div>
  );
};

export default HamburgerButton;
