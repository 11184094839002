import { Avatar, Col, Row, Space, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Studies } from "../../data/Studies";


/**
 * props of a project list
 */
export type StudiesItemProps = {
    studies: Studies;
};


const StudiesItem = (props: StudiesItemProps) => {
    const date = moment(props.studies.startDate).format("YYYY") + "-" + moment(props.studies.endDate).format("YYYY");

    return <Col sm={12} xs={24}>
        <Row gutter={[8, 8]}>
            <Col md={2} sm={24} style={{width: "100%"}}>
                <Space align="center" style={{width: "100%", justifyContent: "center"}}><Avatar size={64} shape="square"
                    src={props.studies.company.logo.url} />
                </Space>
            </Col>

            <Col md={22} sm={24}>
                <Row>
                    <Col span={24}>
                        <Typography.Paragraph style={{ marginBottom: ".1rem", fontSize: "110%" }} strong={true}>
                            {props.studies.company.title}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ marginBottom: ".1rem" }} type="secondary">
                            {props.studies.degree + ", " + props.studies.title}
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ marginBottom: ".1rem" }} type="secondary">
                            {date}
                        </Typography.Paragraph>
                    </Col>
                    <Col>
                        <div className="react-markdown-wrapper">
                            <ReactMarkdown>{props.studies.description}</ReactMarkdown>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>;
};

export default StudiesItem;